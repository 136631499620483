import { makeApi } from '@zodios/core';
import { z } from 'zod';

export const memberApi = makeApi([
  {
    alias: 'importMemberStatus',
    method: 'get',
    path: '/line/v1/member/import_member_status/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number(),
        type: 'Query',
      },
    ],
    response: z.object({
      is_running: z.boolean(),
    }),
  },
  {
    alias: 'memberImportLineApi',
    method: 'post',
    path: '/line/v1/member/oa_member/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({
          bot_id: z.number().int(),
        }),
      },
    ],
    response: z.object({ result: z.string() }),
  },
]);
