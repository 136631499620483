import { z } from 'zod';

export const specialSellerTypeEnum = {
  NAME: 'name',
  UNIFIED_BUSINESS_NUMBER: 'unified_business_number',
} as const;

export const specialSellerTypeSchema = z.nativeEnum(specialSellerTypeEnum);

export type SpecialSellerType = z.infer<typeof specialSellerTypeSchema>;
