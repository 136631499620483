import { z } from 'zod';

import { LineFieldTypeEnum, ParameterTypeEnum } from 'shared/models/editor/common';

const EditorBindlinkParameterSchema = z.object({
  key: z.string(),
  data: z.object({
    function: z.literal('bind_link'),
    bindlink_id: z.number().int(),
  }),
  type: z.literal(ParameterTypeEnum.standard),
});

const EditorStringParameterSchema = z.object({
  key: z.string(),
  data: z.object({
    function: z.null(),
  }),
  type: z.literal(ParameterTypeEnum.custom_string),
});

export const EditorSmsDataSchema = z.object({
  module_id: z.literal(LineFieldTypeEnum.SMS),
  data: z.object({
    text: z.string(),
  }),
  parameters: z.union([EditorStringParameterSchema, EditorBindlinkParameterSchema]).array(),
});
