/* eslint-disable @typescript-eslint/no-explicit-any -- There are many uses of any in this file */
import type { EditorDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { Report } from 'components/Report';
import type { TaskDoneEvent } from 'context/socketContext/event/taskDone';
import type { TagMetadataItem } from 'shared/models/tag/metadata';

export type { TracelinkMetadata as ITracelink } from 'shared/api/tracelink';

export type SwitchNames =
  | 'enable_bind_link'
  | 'enable_breaking_news_template'
  | 'enable_caac_migration'
  | 'enable_customer_journey'
  | 'enable_deep_link_v2'
  | 'enable_maac_dashboard'
  | 'enable_openapi_webhook'
  | 'enable_v2_prize_category'
  | 'openapi_event_api'
  | 'show_auto_segment_modal'
  | 'enable_share_link'
  | 'enable_journey_common_descendant_detection'
  | 'enable_billing_mechanism'
  | 'enable_new_editor'
  | 'enable_new_pricing_for_widget'
  | 'enable_autoreply_api_client'
  | 'enable_broadcast_api_client'
  | 'enable_journey_api_client'
  | 'enable_members_api_client'
  | 'enable_segment_api_client'
  | 'enable_line_message_editor_notification';

// query
export interface ListResponse<T> {
  next: null | string;
  previous: null | string;
  results: T[];
}
export interface PageResponse<T> {
  count: number;
  next: null | string;
  previous: null | string;
  results: T[];
}

export interface NormalizedPage {
  count: number;
  next: string | null;
  allIds: number[];
}

export type Entity<FilterType extends string, T> = Record<FilterType, NormalizedPage> & {
  byId: Record<string, T>;
};

interface BaseMember {
  id: number;
  name: string | null;
  avatar: string | null;
}

export enum MemberStatus {
  auth = 'auth',
  follow = 'follow',
  unfollow = 'unfollow',
}

export const MemberStatusTranslationKeyMap: Record<keyof typeof MemberStatus, string> = {
  auth: 'member.auth',
  follow: 'member.follow',
  unfollow: 'member.unfollow',
};

enum GenderOptions {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
  UNKNOWN = 'unknown',
}

/**
 * Standard record in the response to GET requests at `/line/v1/member/`
 */
export interface SuperMember extends BaseMember {
  line_id: string;
  source: string; // group | room | user
  original_name: string | null;
  email: string | null;
  original_email: string | null;
  mobile: string | null;
  original_mobile: string | null;
  status: keyof typeof MemberStatus;
  create_by: string;
  is_tester: boolean;
  bind_id: string | null;
  gender: GenderOptions;
  health: string;
  birthday: string; // YYYY-MM-DD, where year might be 9999 when unknown
  // #region ISO String
  last_engaged: string | null;
  created_at: string;
  updated_at: string;
  // #endregion
  bot: number;
  tags: TagMetadataItem[];
}

// list
export type Member = Pick<
  SuperMember,
  | 'id'
  | 'name'
  | 'avatar'
  | 'create_by'
  | 'created_at'
  | 'updated_at'
  | 'last_engaged'
  | 'tags'
  | 'status'
  | 'line_id'
  | 'bind_id'
>;

export type Audience = {
  id: number;
  name: string;
  member_count: number;
};

export enum TemplateCategory {
  DPM = 'dpm',
  BROADCAST = 'broadcast',
  RETARGETING = 'retargeting',
  WEBHOOK_TRIGGER = 'webhook_trigger',
  GUIDE_LINK = 'guide_link',
  OPENAPI = 'openapi',
  MAAC = 'maac',
  SALESFORCE = 'salesforce',
}

export interface Template {
  id: number;
  name: string;
  category: TemplateCategory;
  bot: number;
  messages: EditorDataType[];
  thumbnail_info: number[];
}

/**
 * @deprecated We introduced organization level plan in Q124, so this bot/channel level plan is deprecated.
 */
export enum Plan {
  TRIAL_PLAN = 0,
  GROWING_PLAN = 1,
  DATA_PLAN = 2,
  BRAND_PLAN = 3,
}

export type TesterCategory =
  | 'broadcast'
  | 'webhook_trigger'
  | 'guide_link'
  | 'retargeting'
  | 'dpm'
  | 'openapi';
export interface GoogleAnalyticSetting {
  organization: number;
  view_id: string;
  website_url: string | null;
}

type MessageEventType =
  | 'ping' // PING
  | 'chat' // CHAT
  | 'productfeed' // PRODUCT_FEED
  | 'coupon' // COUPON
  | 'audience' // AUDIENCE
  | 'line_broadcast' // LINE_BROADCAST
  | 'line_dpm' // LINE_DPM
  | 'import_member' // IMPORT_MEMBER
  | 'export_member' // EXPORT_MEMBER
  | 'retarget_message' // RETARGET_MESSAGE
  | 'richmenu' // RICHMENU
  | 'guidelink' // GUIDELINK
  | 'export_lottery_records' // Lucky Draw Reports
  | 'segment_ready' // Segment Calculation Done
  | 'export_broadcast_message' // Broadcast reports exported
  | 'export_auto_reply' // Auto-reply reports exported
  | 'export_dynamic_product_message' // DPM reports exported
  | 'export_retarget_message' // Retarget reports exported
  | 'export_richmenu' // Richmenu reports exported
  | 'export_out_link' // Deeplink reports exported
  | 'export_deep_link' // Tracelink reports exported
  | 'export_prize_records' // Prize records exported
  | 'export_referral_records' // Referral records exported
  | TaskDoneEvent['type']; // Task done

export interface BaseWebSocketEvent {
  type: MessageEventType;
}

export interface WebSocketEvent<T extends MessageEventType> {
  type: T;
}

export type ExportMemberEvent = WebSocketEvent<'export_member'>;
export type ImportMemberEvent = WebSocketEvent<'import_member'>;
export type ExportActivityRecordEvent = WebSocketEvent<'export_lottery_records'>;
export type SegmentReadyEvent = WebSocketEvent<'segment_ready'>;
export type ExportBroadcastReports = WebSocketEvent<'export_broadcast_message'>;
export type ExportAutoReplyReports = WebSocketEvent<'export_auto_reply'>;
export type ExportDpmReports = WebSocketEvent<'export_dynamic_product_message'>;
export type ExportRetargetReports = WebSocketEvent<'export_retarget_message'>;
export type ExportRichmenuReports = WebSocketEvent<'export_richmenu'>;
export type ExportTracelinkReports = WebSocketEvent<'export_out_link'>;
export type ExportDeeplinkReports = WebSocketEvent<'export_deep_link'>;
export type ExportPrizeRecords = WebSocketEvent<'export_prize_records'>;
export type ExportReferralRecords = WebSocketEvent<'export_referral_records'>;

export type ExportTaskMessage = {
  key: string;
  messages: string;
};

export enum MessageType {
  NEW_FRIEND = 1,
  ORIGINAL_FRIEND = 2,
  BOUND_FRIEND = 3,
}

export enum WebhookEventType {
  MESSAGE = 1,
  POSTBACK = 2,
  FOLLOW = 3,
  BEACON = 4,
  TIME = 100,
  MESSAGE_EDITOR = 101,
  POSTBACK_EDITOR = 102,
}

type BaseCounts = {
  trigger_counts: number;
  reply_counts: number;
};

export type WebhookTriggerCounts = {
  [MessageType.NEW_FRIEND]: BaseCounts;
  [MessageType.ORIGINAL_FRIEND]: BaseCounts;
  [MessageType.BOUND_FRIEND]: BaseCounts;
  total: BaseCounts;
};

export interface Message {
  id: number;
  messages: EditorDataType[];
  enable: boolean;
  trigger_type: MessageType;
  setting: number;
}

export enum NoDisturbInterval {
  NONE = 0,
  ONCE_A_DAY = 24,
  ONCE_A_WEEK = 168,
  ONCE_TWO_WEEKS = 336,
  ONCE_THREE_WEEKS = 504,
  ONCE_FOUR_WEEKS = 672,
}

export interface WebhookTriggerMetric {
  all: number;
  follower: number;
  keyword: number;
  time: number;
  archived: number;
}

export enum WebHookTriggerReference {
  DP_MSG = '1',
  BROADCAST_MSG = '2',
  GUIDE_LINK_MSG = '3',
  RETARGET_MSG = '4',
  RICHMENU = '5',
  WEBHOOK_TRIGGER_SETTING_MSG = '6',
}
export interface WebhookTriggerSetting {
  id: number;
  messages: Message[];
  enable: boolean;
  name: string;
  event_type: WebhookEventType;
  trigger_code: string;
  tags: string[];
  created_at: string;
  updated_at: string;
  bot: number;
  no_disturb_interval: NoDisturbInterval | null;
  counts: WebhookTriggerCounts;
  archived: boolean;
  references: { [key in WebHookTriggerReference]?: number[] }; // Partial<Record<WebHookTriggerReference, number[]>>;
}
export interface ApiBroadcast {
  id: number;
  name: string;
  created_at: string;
  archived: boolean;
  report?: Report;
  count?: number;
}

export interface ApiBroadcastReport {
  id: number;
  report?: Report;
  count: number;
}

export interface ProductFeed {
  created_at: ISOString;
  file_name: string | null;
  id: number;
  last_fetched_at: ISOString;
  name: string;
  organization: number;
  scheduled_fetch_time: ISOString;
  status: number;
  updated_at: ISOString;
  url_feed: string | null;
  user_agent: string | null;
}

export enum ActivityCategory {
  luckyWheel = 1,
  lotteryPoetry = 2,
  capsuleMachine = 3,
  slotMachine = 4,
  diceMonopoly = 5,
}

export const activityCategoryTranslationKeyMap: Record<`${ActivityCategory}`, string> = {
  1: 'glossary.luckyWheel',
  2: 'glossary.lotteryPoetry',
  3: 'glossary.capsuleMachine',
  4: 'glossary.slotMachine',
  5: 'glossary.diceMonopoly',
};

/**
 * @example "2022-01-01T00:00:00+08:00"
 */
export type ISOString = string;

export interface TesterV2 {
  id: number;
  name: string | null;
  original_name: string | null;
  avatar: string | null;
  status: MemberStatus;
}

/**
 * @example "+886912345678"
 */
export type E164PhoneNumber = string;

/**
 * @example "Asia/Taipei"
 */
export type TimezoneDatabaseName = 'Asia/Taipei' | 'Asia/Tokyo' | 'Asia/Bangkok' | string;

/**
 * See https://github.com/chatbotgang/rubato/wiki/API-Version-1-System#get-universal-template
 * @deprecated use `UniversalTemplateCategory` in `src/shared/types/rubato.ts` instead
 */
export enum UniversalTemplateCategory {
  // Userflow
  USERFLOW_TAG = 'USERFLOW_TAG',
  USERFLOW_SEGMENT = 'USERFLOW_SEGMENT',
  USERFLOW_BROADCAST = 'USERFLOW_BROADCAST',
  USERFLOW_RICHMENU = 'USERFLOW_RICHMENU',
  USERFLOW_PRIZE = 'USERFLOW_PRIZE',
  USERFLOW_AUTOREPLY = 'USERFLOW_AUTOREPLY',
  USERFLOW_DEEPLINK = 'USERFLOW_DEEPLINK',
  USERFLOW_WIDGET = 'USERFLOW_WIDGET',
  USERFLOW_JOURNEY = 'USERFLOW_JOURNEY',
  USERFLOW_CHAT = 'USERFLOW_CHAT',
  // Prize
  PRIZE_MESSAGE_ONLINE_COUPON = 'PRIZE_MESSAGE_ONLINE_COUPON',
  PRIZE_MESSAGE_OFFLINE_COUPON = 'PRIZE_MESSAGE_OFFLINE_COUPON',
  PRIZE_MESSAGE_LINE_COUPON = 'PRIZE_MESSAGE_LINE_COUPON',
  PRIZE_MESSAGE_BRAND_COUPON = 'PRIZE_MESSAGE_BRAND_COUPON',
  PRIZE_MESSAGE_GAME_COUPON = 'PRIZE_MESSAGE_GAME_COUPON',
  PRIZE_MESSAGE_INTEGRATION_COUPON = 'PRIZE_MESSAGE_INTEGRATION_COUPON',
  PRIZE_MESSAGE_POINTS_COUPON = 'PRIZE_MESSAGE_POINTS_COUPON',
  // Breaking News
  BREAKING_NEWS_MESSAGE = 'BREAKING_NEWS_MESSAGE',
  // Retargeting
  RETARGETING_MESSAGE = 'RETARGETING_MESSAGE',
  // Referral V2
  REFERRAL_MESSAGE_V2_INVITATION = 'REFERRAL_MESSAGE_V2_INVITATION',
  REFERRAL_MESSAGE_V2_INVITATION_SUCCESS_FOR_INVITEE = 'REFERRAL_MESSAGE_V2_INVITATION_SUCCESS_FOR_INVITEE',
  REFERRAL_MESSAGE_V2_INVITATION_FAILURE_FOR_INVITEE = 'REFERRAL_MESSAGE_V2_INVITATION_FAILURE_FOR_INVITEE',
  REFERRAL_MESSAGE_V2_INVITATION_SUCCESS_FOR_INVITER = 'REFERRAL_MESSAGE_V2_INVITATION_SUCCESS_FOR_INVITER',
  REFERRAL_MESSAGE_V2_INVITATION_FAILURE_FOR_INVITER = 'REFERRAL_MESSAGE_V2_INVITATION_FAILURE_FOR_INVITER',
  REFERRAL_MESSAGE_V2_PRIZE_REDEMPTION_REQUIREMENT_MET = 'REFERRAL_MESSAGE_V2_PRIZE_REDEMPTION_REQUIREMENT_MET',
}
