import type { TagMetadataItem } from 'shared/models/tag/metadata';
import type { TagMetadataRecords } from 'shared/types/tag';

import { stripSpaces } from 'utils/string/whiteSpaces';

/**
 * Get tag name by ID
 *
 * @param tagId - Single tag ID
 * @param tagMetadataRecords - Tag metadata records
 * @returns Tag name or empty string
 */
export const getTagNameById = (tagId: number, tagMetadataRecords?: TagMetadataRecords): string =>
  tagId && tagMetadataRecords ? (tagMetadataRecords[tagId.toString()]?.name ?? '') : '';

/**
 * Get tag names by IDs
 *
 * @param tagIds - Array of tag IDs
 * @param tagMetadataRecords - Tag metadata records
 * @returns Tag names
 */
export const getTagNamesByIds = (
  tagIds: number[],
  tagMetadataRecords?: TagMetadataRecords,
): string[] =>
  tagIds
    .map((tagId) => (tagId ? getTagNameById(tagId, tagMetadataRecords) : ''))
    .filter((f) => f !== '');

/**
 * Get tag ID by name
 *
 * @param tagName - Tag name
 * @param tagMetadataItems - Tag items
 * @returns Tag ID or 0
 */
export const getTagIdByName = (tagName: string, tagMetadataItems?: TagMetadataItem[]): number =>
  tagName && tagMetadataItems ? (tagMetadataItems.find((f) => f.name === tagName)?.id ?? 0) : 0;

/**
 * Get tag IDs by names
 *
 * @param tagNames - Tag names
 * @param tagMetadataItems - Tag metadata items
 * @returns Tag IDs
 */
export const getTagIdsByNames = (
  tagNames: string[],
  tagMetadataItems?: TagMetadataItem[],
): number[] =>
  tagNames
    .map((tagName) => (tagName ? getTagIdByName(tagName, tagMetadataItems) : 0))
    .filter((f) => f !== 0);

/**
 * Check to see if a tag name exists; note: this is case sensitive!
 *
 * @param tagName - Tag name to check
 * @param tagMetadataItems - Tag metadata items
 * @returns Tag IDs
 */
export const tagNameExists = (
  tagName: string,
  tagMetadataItems?: TagMetadataItem[],
): boolean | null => {
  return tagMetadataItems ? tagMetadataItems.some((element) => element.name === tagName) : null;
};

/**
 * Sanitize a tag name
 *
 * @param name - Tag name to be sanitized
 * @returns Sanitized tag name
 */
export const sanitizeTagName = (name: string): string => {
  return stripSpaces(name);
};
