import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { apiHooks } from 'shared/api';
import { sanitizeTagName } from 'utils/tag';

const useTagMutationOnSuccess = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: apiHooks.getKeyByAlias('tagManagerList') });
    queryClient.invalidateQueries({ queryKey: apiHooks.getKeyByAlias('tagManagerListMetrics') });
    queryClient.invalidateQueries({ queryKey: apiHooks.getKeyByAlias('tagMetadata') });
  }, [queryClient]);
};

export const useTagCreateMutation = () => {
  const onSuccess = useTagMutationOnSuccess();

  return apiHooks.useTagCreate(undefined, {
    onMutate: (data) => {
      return { ...data, name: sanitizeTagName(data.name) };
    },
    onSuccess,
  });
};

export const useTagUpdateMutation = ({ tagId }: { tagId: number }) => {
  const onSuccess = useTagMutationOnSuccess();

  return apiHooks.useTagUpdate(
    { params: { tagId } },
    {
      onMutate: (data) => {
        return { ...data, name: sanitizeTagName(data.name) };
      },
      onSuccess,
    },
  );
};

export const useTagDeleteMutation = ({ tagId }: { tagId: number }) => {
  const onSuccess = useTagMutationOnSuccess();

  return apiHooks.useTagDelete(
    { params: { tagId } },
    {
      onSuccess,
    },
  );
};
