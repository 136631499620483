import { z } from 'zod';

import { EditorFlexDataSchema } from 'shared/models/editor/data/flex';
import { EditorSmsDataSchema } from 'shared/models/editor/data/sms';

// This is a generic placeholder useful until we fully map out all message types
// Module IDs included here are only those that don't have proper schemas yet
const EditorGenericDataSchema = z.object({
  module_id: z.nativeEnum({
    Text: 1,
    Image: 2,
    ImageMap: 3,
    Card: 4,
    Carrousel: 5,
    Video: 8,
    ImageCarousel: 9,
    News: 12,
    Prize: 13,
    ImageMapFlex: 14,
    ImageMapCarousel: 15,
  } as const),
  data: z.any(),
  parameters: z.any(),
  format: z.any(),
  quick_reply: z.any(),
});

// TODO: these types need to be vastly expanded to cover all message types
export const EditorDataSchema = z.union([
  EditorSmsDataSchema,
  EditorFlexDataSchema,
  EditorGenericDataSchema,
]);
