import { z } from 'zod';

// Note: SMS broadcasts use "failed" instead of "fail" as a status
const BroadcastSmsStatusEnum = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  SENDING: 'sending',
  SENT: 'sent',
  FAIL: 'failed',
} as const;

export const BroadcastSmsStatusSchema = z.nativeEnum(BroadcastSmsStatusEnum);
