import { z } from 'zod';

import type { FlexContainer } from '@line/bot-sdk';

import { LineFieldTypeEnum, ParameterTypeEnum } from 'shared/models/editor/common';

const EditorParameterSchema = z.object({
  key: z.string(),
  type: z.nativeEnum(ParameterTypeEnum),
  data: z.unknown(),
  mappingKey: z.string().optional(),
  isShowPopup: z.boolean().optional(),
});

const EditorLinkParameterData = z.object({
  function: z.literal('link'),
  open_external_browser: z.boolean(),
  url: z.string(),
  utm_campaign: z.string(),
  utm_source: z.string(),
  utm_medium: z.string(),
  utm_content: z.string(),
  tag_list: z.string().array(),
});

const EditorLinkParameterSchema = EditorParameterSchema.extend({
  data: EditorLinkParameterData.extend({ messagelink_id: z.number().int().optional() }),
});

export const EditorFlexDataSchema = z.object({
  module_id: z.literal(LineFieldTypeEnum.Flex),
  data: z.object({
    notification_text: z.string(),
    contents: z.custom<FlexContainer>(), // This needs a validator for it to be effective
  }),
  format: z.record(z.string(), z.unknown()).optional(),
  parameters: EditorLinkParameterSchema.array(),
  quick_reply: z.object({ items: z.array(z.unknown()) }).optional(),
});
