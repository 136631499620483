import { Iso8601DateSchema } from '@chatbotgang/etude/zod/Iso8601DateSchema';
import { z } from 'zod';

export const BroadcastSmsCreateParametersSchema = z.discriminatedUnion('status', [
  z
    .object({
      status: z.literal('draft'),
      bot_id: z.number().int(),
      schedule_time: Iso8601DateSchema.nullable(),
      name: z.string(),
      audience_id: z.number().int(),
      message: z.object({
        text: z.string(),
        parameters: z.array(
          z.object({
            position_key: z.string(),
            action: z.enum(['bind_link']), // TODO: add other actions
            kwargs: z.object({
              id: z.number().int(),
            }),
          }),
        ),
      }),
    })
    .strict(),
  z
    .object({
      status: z.literal('scheduled'),
      bot_id: z.number().int(),
      schedule_time: Iso8601DateSchema,
      name: z.string(),
      audience_id: z.number().int(),
      message: z.object({
        text: z.string().min(1),
        parameters: z.array(
          z.object({
            position_key: z.string(),
            action: z.enum(['bind_link']),
            kwargs: z.object({
              id: z.number().int(),
            }),
          }),
        ),
      }),
    })
    .strict(),
]);
