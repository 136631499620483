import { makeApi } from '@zodios/core';
import { z } from 'zod';

const organizationItemSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  uuid: z.string(),
  enable_two_factor: z.boolean(),
});

export const organizationApi = makeApi([
  {
    alias: 'organization',
    method: 'get',
    path: '/organization/v1/organization/:organizationId/',
    response: organizationItemSchema,
  },
  {
    alias: 'organizationUpdate',
    method: 'patch',
    path: '/organization/v1/organization/:organizationId/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: organizationItemSchema.omit({ id: true }).partial(),
      },
    ],
    response: z.never(),
  },
]);
