export const LineFieldTypeEnum = {
  // Preview PNP sms message
  SMS: 0,
  Text: 1,
  Image: 2,
  ImageMap: 3,
  Card: 4,
  Carrousel: 5,
  Video: 8,
  ImageCarousel: 9,
  // skip 10, 11 used in staging
  News: 12,
  Prize: 13,
  Flex: 100001,
  ImageMapFlex: 14,
  ImageMapCarousel: 15,
} as const;

export const ParameterTypeEnum = {
  /** MessageBuilder.DYNAMIC_TYPE_STANDARD */
  standard: 'standard',
  /** MessageBuilder.DYNAMIC_TYPE_OBJECT */
  object: 'object',
  /** MessageBuilder.DYNAMIC_TYPE_CUSTOM_STRING */
  custom_string: 'custom_string',
  /** MessageBuilder.DYNAMIC_TYPE_CUSTOM_OBJECT */
  custom_object: 'custom_object',
} as const;
