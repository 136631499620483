import { z } from 'zod';

export const TagItemSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  available_days: z.number().nullable(),
  organization: z.number().int(),
  member_count: z.number().int(),
  metadata: z
    .object({
      source: z.enum(['maac', 'caac']),
    })
    .nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
});

export type TagItem = z.output<typeof TagItemSchema>;
