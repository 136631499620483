import { z } from 'zod';

export const CdpTagsSourceSchema = z.enum(['maac', 'caac']);

const CdpTagSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  source: CdpTagsSourceSchema,
});

export const CdpTagsSchema = z.object({
  tags: CdpTagSchema.array(),
});

export type CdpTags = z.output<typeof CdpTagsSchema>;

export type CdpTag = z.output<typeof CdpTagSchema>;

export type CdpTagId = CdpTag['id'];

export type CdpTagSource = CdpTag['source'];

export type CdpTagName = CdpTag['name'];
