import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { PageResponseSchema } from 'shared/models/common';
import { TagItemSchema } from 'shared/models/tag/item';
import { TagMetadataItemSchema } from 'shared/models/tag/metadata';

export const tagApi = makeApi([
  {
    alias: 'tagManagerList',
    method: 'get',
    path: '/tag/v2/tag/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'is_permanent',
        schema: z.boolean().optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'page_number',
        schema: z.number().int().optional(),
        type: 'Query',
      },
      // Note: this defaults to `100` and also cannot exceed `100`
      {
        name: 'page_size',
        schema: z.number().int().max(100).optional(),
        type: 'Query',
      },
    ],
    response: PageResponseSchema.extend({
      results: TagItemSchema.strict().array(),
    }),
  },
  {
    alias: 'tagManagerListMetrics',
    method: 'get',
    path: '/tag/v1/tag/metric/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        all: z.number().int(),
        is_period: z.number().int(),
        is_permanent: z.number().int(),
      })
      .strict(),
  },
  {
    alias: 'tagMetadata',
    method: 'get',
    path: '/tag/v1/tag/raw_tags/',
    response: TagMetadataItemSchema.strict().array(),
  },
  {
    alias: 'tagCreate',
    method: 'post',
    path: '/tag/v1/tag/',
    parameters: [
      {
        name: 'body',
        schema: TagItemSchema.pick({
          name: true,
          available_days: true,
        }).strict(),
        type: 'Body',
      },
    ],
    response: TagItemSchema.pick({
      id: true,
      name: true,
      available_days: true,
      member_count: true,
    }).strict(),
  },
  {
    alias: 'tagUpdate',
    method: 'patch',
    path: '/tag/v1/tag/:tagId/',
    parameters: [
      {
        name: 'body',
        schema: TagItemSchema.pick({
          id: true,
          name: true,
          available_days: true,
        }).strict(),
        type: 'Body',
      },
    ],
    response: TagItemSchema.strict(),
  },
  {
    alias: 'tagDelete',
    method: 'delete',
    path: '/tag/v1/tag/:tagId/',
    response: z.never(),
  },
]);
