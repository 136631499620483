import { Iso8601DateSchema } from '@chatbotgang/etude/zod/Iso8601DateSchema';
import { makeApi } from '@zodios/core';
import { z } from 'zod';

import type { ZodiosResponseByAlias } from '@zodios/core';

import { BroadcastStatusSchema } from 'shared/models/broadcast/common';
import { ExportResponseSchema, ListResponseSchema } from 'shared/models/common';
import { EditorDataSchema } from 'shared/models/editor/data';
import {
  ReportMessageSchema,
  ReportShareSchema,
  ReportTotalSchema,
  ReportUrlSchema,
} from 'shared/models/report';
import { SegmentStatusSchema } from 'shared/models/segment/common';

const BroadcastDetailResponseSchema = z
  .object({
    id: z.number().int(),
    bot: z.number().int(),
    name: z.string(),
    status: BroadcastStatusSchema,
    schedule_time: z.string(),
    last_edited_at: z.string(),
    audience: z.number(),
    audience_info: z.object({
      id: z.number().int(),
      name: z.string(),
      size: z.number().int(),
      size_updated_time: z.string().nullable(),
      status: SegmentStatusSchema,
      deleted: z.boolean(),
    }),
    audience_name: z.string(),
    /** This is AKA sent count or push count */
    count: z.number(),
    dynamic_send_time: z.boolean(),
    is_open_count_trackable: z.boolean(),
    messages: EditorDataSchema.array(),
    smart_send: z.boolean(),
  })
  .strict();

const BroadcastCreateParametersSchema = z
  .object({
    schedule_time: z.string(),
    name: z.string(),
    bot: z.number().int(),
    audience: z.number().int(),
    messages: EditorDataSchema.array(),
    /** This is always false, based on existing code */
    dynamic_send_time: z.boolean(), // false,
    status: BroadcastStatusSchema,
    smart_send: z.boolean(),
  })
  .strict();

const BroadcastUpdateParametersSchema = BroadcastCreateParametersSchema;

export const broadcastApi = makeApi([
  {
    alias: 'broadcastList',
    method: 'get',
    path: '/line/v2/message/broadcast/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'status',
        // TODO: integrate "search" into the actual schema or make another enum
        schema: BroadcastStatusSchema.or(z.enum(['search'])).optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'cursor',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'limit',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: ListResponseSchema.extend({
      results: z
        .object({
          id: z.number().int(),
          name: z.string(),
          audience: z.object({
            name: z.string(),
            size: z.number().int(),
            status: SegmentStatusSchema,
            archive: z.boolean(),
          }),
          status: BroadcastStatusSchema,
          schedule_time: Iso8601DateSchema,
          last_edited_at: Iso8601DateSchema,
          smart_send: z.boolean(),
          report: z.object({
            clicks: z.number().int(),
            open_count: z.number().int().nullable(),
            push_count: z.number().int().nullable(),
            transaction_revenue: z.number(), // Float
            unique_clicks: z.number().int().nullable(),
          }),
        })
        .strict()
        .array(),
    }),
  },
  {
    alias: 'broadcastListMetrics',
    method: 'get',
    path: '/line/v1/message/broadcast/metric/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        status: BroadcastStatusSchema,
        count: z.number().int(),
      })
      .strict()
      .array(),
  },
  {
    alias: 'broadcastListSearchResultCount',
    method: 'get',
    path: '/line/v2/message/broadcast/count/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'status',
        schema: BroadcastStatusSchema.or(z.enum(['search'])).optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        count: z.number().int(),
      })
      .strict(),
  },
  {
    alias: 'broadcastDetail',
    method: 'get',
    path: '/line/v1/message/broadcast/:broadcastId/',
    response: BroadcastDetailResponseSchema,
  },
  {
    alias: 'broadcastReport',
    method: 'get',
    path: '/line/v1/message/broadcast/:broadcastId/report/',
    parameters: [
      {
        name: 'start_date',
        schema: z.string(),
        type: 'Query',
      },
      {
        name: 'end_date',
        schema: z.string(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        id: z.number().int(),
        name: z.string(),
        audience: z.number(),
        status: BroadcastStatusSchema,
        schedule_time: z.string(),
        opencountv2_enable: z.boolean(),
        report: z.object({
          total: ReportTotalSchema.merge(
            z.object({
              unique_open_count: z.number().int().nullable(),
            }),
          ),
          urls: ReportUrlSchema.array(),
          messages: ReportMessageSchema.array(),
          shares: ReportShareSchema.array(),
        }),
      })
      .strict(),
  },
  {
    alias: 'broadcastMetadata',
    method: 'get',
    path: '/line/v1/message/broadcast/raw_broadcasts/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'status',
        schema: BroadcastStatusSchema.or(z.enum(['search'])).optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'is_open_count_trackable',
        schema: z.boolean().optional(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        id: z.number().int(),
        name: z.string(),
        schedule_time: z.string(),
        status: BroadcastStatusSchema,
        audience_name: z.string(),
      })
      .strict()
      .array(),
  },
  {
    alias: 'broadcastCreate',
    method: 'post',
    path: '/line/v1/message/broadcast/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BroadcastCreateParametersSchema,
      },
    ],
    response: BroadcastDetailResponseSchema,
  },
  {
    alias: 'broadcastUpdate',
    method: 'patch',
    path: '/line/v1/message/broadcast/:broadcastId/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BroadcastUpdateParametersSchema,
      },
    ],
    response: BroadcastDetailResponseSchema,
  },
  {
    alias: 'broadcastDelete',
    method: 'delete',
    path: '/line/v1/message/broadcast/:broadcastId/',
    response: z.never(),
  },
  {
    alias: 'broadcastExport',
    method: 'post',
    path: '/line/v1/message/broadcast/export/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        // Note: the original Broadcast Export service is configured in a strange way
        schema: z
          .object({
            bot_id: z.number().int(), // Might also need a `bot` alias here
            start_date: z.string(),
            end_date: z.string(),
            status: BroadcastStatusSchema.optional(),
            name_q: z.string().optional(),
            pk: z.number().int().optional(),
          })
          .strict(),
      },
    ],
    response: ExportResponseSchema,
  },
]);

export type BroadcastListMetricsResponse = ZodiosResponseByAlias<
  typeof broadcastApi,
  'broadcastListMetrics'
>;
