import { z } from 'zod';

import type { ValueOf } from 'type-fest';

export const BroadcastStatusEnum = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  SENDING: 'sending',
  SENT: 'sent',
  FAIL: 'fail',
} as const;

export const BroadcastStatusSchema = z.nativeEnum(BroadcastStatusEnum);

export type BroadcastStatus = z.output<typeof BroadcastStatusSchema>;

export const BroadcastChannelEnum = {
  LINE: 'line',
  SMS: 'sms',
} as const;

export type BroadcastChannel = ValueOf<typeof BroadcastChannelEnum>;
